import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, SEO, Lightbox } from '../components'

class Realisations extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    const {
      data: {
        allFile: { edges: images },
      },
    } = this.props

    const tabImages = images.map(
      (
        {
          node: {
            childImageSharp: {
              fluid: { src, srcSet, originalName },
            },
          },
        },
        index
      ) => ({ src, srcSet, alt: originalName || `Réalisation ${index}` })
    )

    this.state = {
      images: tabImages,
      show: false,
      url: null,
    }
  }

  onClick = event => {
    event.preventDefault()
    this.setState({
      url: event.currentTarget.getAttribute('href'),
      show: true,
    })
  }

  close = () => {
    this.setState({ show: false, url: null })
  }

  render() {
    const { images, show, url } = this.state

    return (
      <Layout>
        <SEO
          title="Réalisations"
          description="Nos réalisations de montage de porte de garage, sectionnelle, basculante et volet roulant RollMatic de la marque Hörmann et également des portes d'entrée"
        />
        <h1 className="title">Nos Réalisations</h1>
        <div className="flex flex-wrap -mx-1 lg:-mx-4">
          {images.map(({ src, srcSet, alt }, index) => (
            <div
              key={index}
              className="my-1 px-1 w-1/2 md:w-1/3 lg:my-4 lg:w-1/4"
            >
              <a href={src} onClick={this.onClick}>
                <img src={src} srcSet={srcSet} alt={alt} />
              </a>
            </div>
          ))}
        </div>
        {show && <Lightbox url={url} images={images} close={this.close} />}
      </Layout>
    )
  }
}

export default Realisations

export const pageQuery = graphql`
  query AllRealisations {
    allFile(filter: { relativeDirectory: { eq: "realisations" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxHeight: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
